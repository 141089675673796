import Services from "../../../services";

class DetailStatusKaryawanApi {
  get() {
    return Services.get("status_pekerja_detail");
  }

  getPage(page, dataLength, key) {
    return Services.get(
      `status_pekerja_detail/page/?per_page=${dataLength}&page=${page}&q=${key}`
    );
  }

  search(key) {
    return Services.get("status_pekerja_detail/page/?per_page=10&page=1&q=" + key);
  }

  create(value) {
    return Services.post("status_pekerja_detail", value);
  }

  update(value) {
    return Services.put("status_pekerja_detail", value);
  }

  delete(value) {
    return Services.post("status_pekerja_detail/delete", value);
  }

  getDropdown() {
    return Services.get("status_pekerja_detail/dropdown?tipe=status pekerja");
  }
}

export default new DetailStatusKaryawanApi();
