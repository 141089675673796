import Services from "../../../services";

class UnitProduksiApi {
  page(params) {
    return Services.get("unit_produksi/page", { params });
  }

  single(params) {
    return Services.get("unit_produksi/single", { params });
  }

  create(value) {
    return Services.post("unit_produksi", value);
  }

  update(value) {
    return Services.put("unit_produksi", value);
  }

  delete(value) {
    return Services.post("unit_produksi/delete", value);
  }
}

export default new UnitProduksiApi();
