import Services from "../../../services";

class StatusKaryawanApi {
  get() {
    return Services.get("status_pekerja");
  }

  getPage(page, dataLength, key) {
    return Services.get(`status_pekerja/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  search(key) {
    return Services.get("status_pekerja/page/?per_page=10&page=1&q=" + key);
  }

  show(value) {
    return Services.put("status_pekerja/show", value);
  }

  hide(value) {
    return Services.put("status_pekerja/hide", value);
  }

  create(value) {
    return Services.post("status_pekerja", value);
  }

  update(value) {
    return Services.put("status_pekerja", value);
  }

  delete(value) {
    return Services.post("status_pekerja/delete", value);
  }

  getDropdownJenis() {
    return Services.get("jenis_pekerja");
  }

  getDropdown() {
    return Services.get("status_karyawan/dropdown");
  }
}

export default new StatusKaryawanApi();
