import Services from "../../../services";

class JabatanApi {
  get() {
    return Services.get("jabatan");
  }

  getDropdown() {
    return Services.get("jabatan/dropdown");
  }

  getPage(page, dataLength, key) {
    return Services.get(`jabatan/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  kode() {
    return Services.get("jabatan/no_baru");
  }

  search(key) {
    return Services.get("jabatan/page/?per_page=10&page=1&q=" + key);
  }

  show(value) {
    return Services.put("jabatan/show", value);
  }

  hide(value) {
    return Services.put("jabatan/hide", value);
  }

  create(value) {
    return Services.post("jabatan", value);
  }

  update(value) {
    return Services.put("jabatan", value);
  }

  delete(value) {
    return Services.post("jabatan/delete", value);
  }
}

export default new JabatanApi();
